<template>
    <div class="form-container">
    <ConfirmModal :key="totalPrice" v-if="show" @close="show = false" :unitPrice="unitPrice" :totalPrice="totalPrice"
                    :amount="amount" :type="'SELL'" :date="eDate" :limit="marketClick" @confirm="accept" :tradeFrom="$route.params.tradeFrom" :tradeTo="$route.params.tradeTo" />
    <form @submit.prevent="" @input="calculate" class="buysell-container">

        <div class="inputs-row">
            <div class="balance">
                <span> موجودی : </span>
                <span> {{$toLocal(credit,$decimal[$route.params.tradeFrom])}} {{$coinLabel[$route.params.tradeFrom]}} </span>
            </div>

            <div class="label-container">
                <div :class="fUnitPrice? 'div-active':''" class="input-p2p">
                    <label for="input">
                        <span :class="fUnitPrice || unitPrice ? 'span-active-p2p':''"> قیمت </span>
                        <input :disabled="marketClick" @input="unitPrice=$toLocal(unitPrice,$decimal[$route.params.tradeTo])" @focus="fUnitPrice=true" v-model="unitPrice" type="text">
                        <img :src="'/coins/'+$route.params.tradeTo+'.png'" alt="">
                    </label>
                </div>
            </div>

            
            <div class="label-container">
                <div :class="fAmount? 'div-active':''" class="input-p2p">
                    <label for="input">
                        <span :class="fAmount || amount ? 'span-active-p2p':''"> مقدار </span>
                        <input @input="amount=$toLocal(amount,$decimal[$route.params.tradeFrom])" @focus="fAmount=true" v-model="amount" type="text">
                        <img :src="'/coins/'+$coinUnit[$route.params.tradeFrom]+'.png'" alt="">
                    </label>
                </div>
            </div>



        </div>


        <div v-if="marketClick" class="marketWarn">
            <img src="../../assets/Icons/warnGreen.svg" alt="">
            <span class="text"> در معامله بازار، بهترین قیمت بازار برای معامله در نظر گرفته میشود </span>
        </div>

        <date-picker 
                        v-if="!marketClick"
                        placeholder="تاریخ انقضا" 
                        v-model="eDate"
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['faDateTime']"
                        :clearable="true"
                        type="datetime"
                        :min="now"
                        :class="'date-fa'"
                        class="date"
        />



        <div class="range-fee">
            <div class="input-range">
                <input v-model="percent" class="rang-inpt" step="0.01" min="0.01" max="100" type="range" name="" id="">
                <div class="dots-container">
                    <span @click="dotsRange(i)" v-for="i in 5" :id="'dot' + i" :key="i" class="dots"></span>
                </div>
            </div>
             <div class="fee">
                <span> کارمزد: {{wage*100}}% </span>
                <span> مقدار: {{percent}}% </span>
            </div>
            <div class="fee">
                <span> کمترین مقدار مجاز: 
                    <span> {{$toLocal(min,$decimal[$route.params.tradeFrom])}} </span>
                        <span class="pricetype"> {{$coinLabel[$route.params.tradeFrom]}} </span> </span>
            </div>
            <div class="fee">
                <span> بیشترین مقدار مجاز: 
                    <span> {{$toLocal(max,$decimal[$route.params.tradeFrom])}} </span>
                    <span class="pricetype"> {{$coinLabel[$route.params.tradeFrom]}} </span> 
                </span>
            </div>
        </div>



        <div class="label-container">
                <div class="input-p2p">
                    <label for="input">
                        <span> مجموع  </span>
                        <span class="total"> {{$toLocal(totalPrice,$decimal[$route.params.tradeTo])}} </span>
                        <img :src="'/coins/'+$route.params.tradeTo+'.png'" alt="">
                    </label>
                </div>
            </div>

        <button @click.prevent="show=true" :disabled="checkBtn" class="sell"> {{checkBtn ? checkBtn :'فروش'}} </button>



    </form>
    </div>
</template>

<script>
import {now} from '../../Lib/georgianToJalali';
import {Loop,tradeFrom,tradeTo} from '../../Lib/reuseableFunction';
import ConfirmModal from './ConfirmModal.vue';
    export default {
        name:'Sell',
        props:['marketClick','role','credit','wage'],
        components:{
            ConfirmModal
        },
        data() {
            return {
                fUnitPrice:false,
                fAmount:false,
                loop:'',
                show:false,
                force:false,


                totalPrice:0,
                percent:0,
                unitPrice:null,
                eDate:null,
                amount:null,

            }
        },
        computed:{
            checkBtn(){
                let errors = ''
                if(!this.state.userInfo.authorized){
                    errors= 'احراز هویت نشدید'
                }else if(!this.marketClick && this.$S2N(this.amount) && !this.$S2N(this.unitPrice)){
                    errors= 'مقدار مجاز نیست'
                }
                else if(this.marketClick && !this.$S2N(this.unitPrice)){
                        errors= 'قیمت را وارد کنید'
                }
                else if(!this.$S2N(this.amount)){
                        errors= 'مقدار را وارد کنید'
                }
                else if((this.$S2N(this.amount) > this.$S2N(this.credit)) & !this.state.userInfo.supervisor ){
                    errors= 'موجودی کافی نیست'
                }
                else if(!this.marketClick & this.state.userInfo.supervisor & (this.$S2N(this.amount) > this.$S2N(this.credit))){
                    errors= 'موجودی بازارگردان کافی نیست'
                }
                else if(this.$S2N(this.amount) < this.min){
                    errors= 'کمتر از مقدار مجاز'
                }
                else if(this.$S2N(this.amount) > this.max){
                    errors= 'بیشتر از مقدار مجاز'
                } else{
                    errors=false;
                }
                return errors ;
            },
            min(){
                return this.role?.minAmount
            },
            max(){
                return this.role?.maxAmount
            },
            now,
            tradeFrom,
            tradeTo
        },
        watch: {
            'unitPrice'(){
                if(!this.marketClick && (!this.unitPrice || this.unitPrice=='0')){
                    this.percent=0;
                }
            },
            'percent'(){
                this.changeRange();
                if(!this.marketClick && (!this.unitPrice || this.unitPrice=='0')){
                    this.percent=0;
                    this.amount=0;
                    this.totalPrice=0;
                }
            },
            'amount'(){
                if(!this.marketClick && (!this.unitPrice || this.unitPrice=='0')){
                    this.amount=0;
                }
            },
            'marketClick'(){
                this.amount=''
                this.unitPrice=''
                this.totalPrice = 0
            }
        },
        methods: {
            async accept() {
                this.state.loading = true;
                let orderDetail;
                let marketType = this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo

                if (!this.marketclick) {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.amount),
                        orderType: `LIMITED_SELL`,
                        expiresAt: this.eDate || null,
                        unitPrice: this.$S2N(this.unitPrice),
                        wholePrice: this.$S2N(this.totalPrice),
                    }
                } else {
                    orderDetail = {
                        marketType: marketType,
                        amount: this.$S2N(this.amount),
                        orderType: `MARKET_SELL`,
                    }
                }
                try {
                    const res = await this.$axios.post(`/orders/sell?force=${ this.force ? true: false}`, orderDetail)
                    if(res){
                        ++this.state.orderChanged;
                        this.$error('', 'سفارش با موفقیت ثبت شد', 'success');
                        this.force = false;
                    }
                } catch (error) {
                    this.state.loading = false
                    if (error.response.data.message === 'NOT_ACCEPTABLE_UNIT_PRICE'){
                        this.$swal.fire({
                                title: 'آیا مطمئن هستید',
                                text: "قیمت سفارش با قیمت میانگین متفاوت میباشد",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonColor: 'rgba(110, 120, 255, 1)',
                                cancelButtonColor: '#d33',
                                confirmButtonText: 'ثبت',
                                cancelmButtonText: 'لغو'
                            }).then(() => {
                                    this.force =true;
                                    this.accept();
                                })
                    }
                    if (error.response.data.message === "the amount of order is bigger than market liquidity") {
                        this.$error('', 'حجم درخواستی بیش از مقدار بازار است.');
                    }
                    
                }
            },
            dotsRange(i){
                console.log(i);
                switch (i) {
                    case 1:{
                        this.percent = 100
                        break;
                    }
                    case 2:{
                        this.percent = 75
                        break;
                    }
                    case 3:{
                        this.percent = 50
                        break;
                    }
                    case 4:{
                        this.percent = 25
                        break;
                    }
                    case 5:{
                        this.percent = 0
                        break;
                    }
                    default:
                        this.percent = 0
                        break;
                }
            },
            changeRange(){
                this.amount = this.credit * (this.percent/100)
                this.calculate();
            },
            calculate() {
                if (!this.marketClick && this.unitPrice && this.amount) {
                    this.localCalculate()
                } else if (this.marketClick && this.$S2N(this.amount)) {
                    this.serverCalculate()
                }
            },
            localCalculate() {
                const up = this.$S2N(this.unitPrice)
                const a = this.$S2N(this.amount)
                this.totalPrice = up*a
            },
            async serverCalculate() {
                this.loop ? this.loop.stop() : null
                const a = this.amount
                await this.$sleep(500)
                if (a === this.amount && this.marketClick) {
                    this.loop = new Loop(this.getPriceFromServer, this.state.time, 'getPriceSell')
                    this.loop.start()
                }
            },
            async getPriceFromServer() {
                if(this.marketClick){
                let amount = this.$S2N(this.amount);
                const [res,] = await this.$http.get('/orders/market-buy-sell-whole', {
                    params: {
                        amount: amount,
                        marketType: this.tradeFrom + '_' + this.tradeTo,
                        orderType: 'MARKET_SELL'
                    }
                })
                if (res) {
                    this.totalPrice = res.baseDTO.wholePrice
                    this.unitPrice = this.$toLocal((this.$S2N(this.totalPrice) / amount), this.$decimal[this.$route.params.tradeTo]);
                }
            }
            },
        },



        beforeDestroy() {
            this.loop.stop() ? this.loop.stop() : '';
        },
        created:function(){
            window.addEventListener("click",event => {
                if(!event.target.closest('.label-container')){
                    this.fUnitPrice=false
                    this.fAmount=false
                }
            })
        }
    }
</script>

<style lang="scss" scoped>
.form-container{
    width: 50%;
}
.sell{
    width: 100%;
    background: var(--red);
    color: white;
}


.range-fee{
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    .fee{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--a-hover);
    }
}




.marketWarn{
    opacity: 0;
    display: flex;
    flex-direction: row;
    column-gap: 5px;
    .text{
        color: var(--darker-primary);
        font-weight: 500;
        font-size: clamp(10px,1vw,12px);
    }
}

.inputs-row{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    width: 100%;
    .balance{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--black);
        font-weight: 400;
        font-size: clamp(10px,1vw,12px);
    }
}
.buysell-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
</style>
<template>
    <div class="shop-container">
        <div class="btns-container">
            <button @click="changeMarketType(false)" :class="{'selected-btn':!marketClick}" class="filter-btn"> حد </button>
            <button @click="changeMarketType(true)" :class="{'selected-btn':marketClick}" class="filter-btn"> بازار </button>
        </div>
        <div class="shop">
            <Buy :role="role" :credit="buyCredit" :wage="state.userInfo.fee" :marketClick="marketClick" />
            <Sell :role="role" :credit="sellCredit" :wage="state.userInfo.fee" :marketClick="marketClick" />
        </div>
    </div>
</template>

<script>
import Buy from './Buy.vue'
import Sell from './Sell.vue'
import {tradeTo,tradeFrom} from "@/Lib/reuseableFunction";
    export default {
        name:'Shop',
        components:{
            Buy,
            Sell
        },
        data() {
            return {
                marketClick:false,
                sellCredit: 0,
                buyCredit: 0,
                coins:[],
                role:{},
            }
        },
        computed:{
            tradeTo,tradeFrom,
        },
        methods: {
            changeMarketType(val){
                this.marketClick=val
            },
            async getWalletCredit() {
                if (this.state.userInfo.authorized) {
                    const res = await this.$axios.get('/wallets/customer-addresses', {
                        params: {
                            coins: `${this.tradeFrom},${this.tradeTo}`,
                            walletType:'P2P'
                        }
                    })
                    this.buyCredit = res[this.tradeTo].credit || 0
                    this.sellCredit = res[this.tradeFrom].credit || 0
                }
                const coins = await this.$axios.get('/coins')
                this.role = coins?.content?.find((a) => a.coin === this.tradeFrom)
            },
        },
        mounted() {
            this.getWalletCredit();
        },
    }
</script>

<style lang="scss" scoped>
.shop-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    .btns-container{
        display: flex;
        flex-direction: row;
        width: 50%;
        .filter-btn{
            width: 50%;
            color: var(--a-hover);
            text-align: center;
            cursor: pointer;
            padding: 4px 0;
            transition: 700ms;
        }
        .selected-btn{
            background: var(--white);
            box-shadow: 0px 0px 4px -1px rgba(65, 70, 192, 0.1);
            border-radius: 4px 4px 0px 0px;
            color: var(--primary) !important;
            transform: translate(-1px,3px);
            z-index: 3;
        }
    }
    .shop{
        display: flex;
        flex-direction: row;
        column-gap: 12px;
        background: var(--white);
        box-shadow: 0px 0px 4px -1px rgb(65 70 192 / 10%);
        border-radius: 4px ;
        padding: 12px 20px;
        z-index: 3;
    }
}
@media only screen and(max-width:500px){
    .shop{
        flex-direction: column !important;
        gap: 70px;
        .buysell-container{
            width: 100%;
        }
    }
}
</style>
<template>
    <div class="trading">
      <vue-trading-view
          :key="$route.params.tradeFrom"
          style="height:400px"
          :options="tradeOption"
      />
    </div>
</template>

<script>
import VueTradingView from 'vue-trading-view/src/vue-trading-view.vue'
export default {
  name: 'Tradingchart',
  components :{
    VueTradingView
  },
  data() {
      return {
         tradeOption:{
              //symbol: 'BINANCE:' + $coinUnit[$route.params.tradeFrom] + 'USDT',
              symbol: this.$route.params.tradeFrom !== 'TETHER' ? 'BINANCE:' + this.$coinUnit[this.$route.params.tradeFrom] + 'USDT' : 'BINANCEUS:USDTUSD',
              height:'710',
              autosize:true,  
             theme:'light',  
                      timezone: 'Asia/Tehran',
                      style: '1',
                      hide_top_toolbar:true,
                      hide_legend:true,
                      hide_bottom_toolbar:false,
                      locale:'fa_IR',
                      enable_publishing: false,
                      withdateranges: true,
                      range: 'ytd',
                      hide_side_toolbar: true,
                      allow_symbol_change: true,
                      enabled_features: [
                          'header_fullscreen_button'
                          ],
                      disabled_features: [
                          'border_around_the_chart',
                          'remove_library_container_border',
                          ],
                      loading_screen:{
                          backgroundColor:'#F2F6FF',
                          foregroundColor: '#F2F6FF',
                          },
                      overrides: {
                          background: '#F2F6FF'
                      }
          }
      }
  },
  mounted () {
  }
  }
</script>

<style lang="scss" scoped>
    .trading {
    width: 100%;

    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
}
</style>
<template>
    <div class="table-container">
        <table>
                <tr class="thead">
                    <td style="text-align:start">قیمت واحد</td>
                    <td style="text-align:center"> مقدار </td>
                    <td style="text-align:end"> قیمت کل </td>
                </tr>
                <tr class="tbody" :class="{'disabled':item.myOrder}" v-for="(item,index) in tableContentsSell" :key="index" :style="{background : 'linear-gradient(to right,rgba(255, 0, 92, .3) '+Math.floor(item.executedPercent)+'%,#F0F0F5 0%)'}">
                    <td class="fail" style="text-align:start">{{item.unitPrice.toLocaleString()}}</td>
                    <td style="text-align:center">{{item.volume}}</td>
                    <td style="text-align:end">{{item.wholePrice.toLocaleString()}}</td>
                </tr>
        </table>
        <div class="average">
            <p class="average-p">{{state.shopInfo.lastPrice.toLocaleString()}}</p>
            <div class="green-line"></div>
        </div>
        <table>
            <tr class="thead">
                    <td style="text-align:start"> قیمت واحد </td>
                    <td style="text-align:center"> مقدار </td>
                    <td style="text-align:end"> قیمت کل </td>
            </tr>
                <tr class="tbody" :class="{'disabled':item.myOrder}"  v-for="(item,index) in tableContentsBuy" :key="index" :style="{background : 'linear-gradient(to right,rgba(51, 158, 0, .3) '+Math.floor(item.executedPercent)+'%,#F0F0F5 0%)'}">
                    <td class="color-green" style="text-align:start">{{item.unitPrice.toLocaleString()}}</td>
                    <td style="text-align:center">{{item.volume}}</td>
                    <td style="text-align:end">{{item.wholePrice.toLocaleString()}}</td>
                </tr>
        </table>
    </div>
</template>

<script>
import {Loop} from "@/Lib/reuseableFunction";
    export default {
        name:'Defth',
        data() {
            return{
                average:1258473000,
                tableContentsSell:[],
                tableContentsBuy:[],
                loop:'',
            }
        },
        methods: {
            async MarketDepth() {
                    const [res,] = await this.$http.get('/orders/order-book', {
                        params: {
                            marketType: this.$route.params.tradeFrom + '_' + this.$route.params.tradeTo
                        }
                    })
                    if(res){
                        this.tableContentsSell = res.sellOrderBook
                        this.tableContentsBuy = res.buyOrderBook
                        this.state.shopInfo.minSell = res.sellOrderBook.length ? res.sellOrderBook[0].unitPrice : 0
                        this.state.shopInfo.maxBuy = res.buyOrderBook.length ? res.buyOrderBook[0].unitPrice : 0
                    }
            },
            async getMarketDepth(){
                this.state.loading=true
                if (this.state.loop) {
                    this.loop = new Loop(this.MarketDepth,this.state.time,'marketDepth:'+this.$route.params.tradeFrom+'-'+this.$route.params.tradeTo)
                    this.loop.start()
                } else {
                    this.MarketDepth()
                }
            }
        },
        async mounted() {
            this.getMarketDepth()
        },
        beforeDestroy() {
            this.loop.stop();
        },
    }
</script>

<style lang="scss" scoped>
td{
    width: 80px;
}
.green-line{
    height: 1px;
    width: 100%;
    // border: 1px solid green;
    background: linear-gradient(-90deg, #4CAF50 0%, rgba(215, 200, 200, 0) 94.91%);


}
.thead{
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: 0;
    height: 40px;
    td{
        font-weight: 400;
        font-size: clamp(10px,1vw,12px);
        color: var(--a-hover);
    }
}
.disabled{
    opacity: .4;
}
.average{
    width: 100%;
    height: 15%;
    min-height: 36px !important;
    text-align: center;
    background: var(--primary-color);
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: var(--green);
    font-weight: 700;
    font-size: clamp(11px,3vw,14px);
}
.table-container{
    width: 25%;
    overflow: scroll;
    display: flex;
    justify-content: start;
    flex-direction: column;
    row-gap: 8px;
    box-sizing: border-box;
    // overflow: scroll;
    padding: 16px;
    background: #F0F0F5;
    box-shadow: 0px 0px 8px -1px rgba(65, 70, 192, 0.2);
    border-radius: 4px;
    // overflow-y: scroll;
}
table{
    height: 40%;
    border-collapse: separate;
    border-spacing: 0px 2px;
    background: transparent;
    tr{
        height: 30px;
    }
}
.tbody{
    position: sticky;
    top: 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    font-weight: 400;
    font-size: clamp(10px,1vw,13px);
}
    @media only screen and(max-width:850px){
    .table-container{
        order: 2;
        // width: 100%;
        max-width: none;
    }
}
</style>
<template>
    <div class="p2p-container">
        <div class="trade-content">
            <div class="coin-trade">
                <DashMarket :key="$route.params.tradeFrom+ state.orderChanged +$route.params.tradeTo+'dashMarket'"/>
                <LastTrades :key="$route.params.tradeFrom+ state.orderChanged +$route.params.tradeTo+'lastTrades'"/>
            </div>
            <div class="view">
                <Tradingview :key="$route.params.tradeFrom+$route.params.tradeTo+'trade'" />
                <Shop :key="$route.params.tradeFrom+ state.orderChanged +$route.params.tradeTo+'shop'" />
            </div>
            <Defth :key="$route.params.tradeFrom+$route.params.tradeTo+'defth'" />
        </div>
        <div v-if="state.userInfo.authorized" class="tables">
            <History />
        </div>
    </div>
</template>

<script>
import DashMarket from '../Panel/Dashboard/DashMarket.vue'
import LastTrades from './LastTrades.vue'
import Tradingview from './Tradingview.vue'
import Shop from './Shop.vue'
import Defth from './Defth.vue'
import History from '../Panel/History/Main.vue'
    export default {
        components: { 
        DashMarket,
        LastTrades,
        Tradingview,
        Shop,
        Defth,
        History 
        },
        
    }
</script>

<style lang="scss" scoped>




.p2p-container{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding: 90px 16px 20px 16px;
    background: var(--white);
    .trade-content{
        display: flex;
        flex-direction: row;
        gap: 16px;
        .view{
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 49%;
        }
        .coin-trade{
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 25%;
            .market-container{
                width: 100% !important;
            }
        }
    }
}
@media only screen and(max-width:1200px){
    .p2p-container{
        padding: 140px 16px;
    }
    .trade-content{
        flex-direction: column !important;
        align-items: center;
        .view{
            width: 100% !important;
        }
        .coin-trade{
            flex-direction: row !important;
            width: 100% !important;
            flex-wrap: wrap;
        }
        .table-container{
            width: 100%;
        }
    }
}
@media only screen and(max-width:800px){
    .p2p-container{
        padding: 180px 16px;
    }
}
</style>